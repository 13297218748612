/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ 
                description, 
                lang, 
                meta, 
                title,
                image, 
                imageWidth, 
                imageHeight,
                canonicalpath,
              }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            imgUrl
            imgWidth
            imgHeight
          }
        }
      }
    `
  )

  var metaTitleTemplate = "%s | "+site.siteMetadata.title
  const metaDescription = description || site.siteMetadata.description
  const metaImage = image? (image.length>0? (image.indexOf("http")===0?image:site.siteMetadata.siteUrl+image): site.siteMetadata.imgUrl) :site.siteMetadata.imgUrl
  const metaImageHeight = {}
  const metaImageWidth = {}

  const metaSiteUrl = {}

  if (canonicalpath !== undefined) {
    metaSiteUrl.property = `og:url`
    metaSiteUrl.content = site.siteMetadata.siteUrl+canonicalpath
  }
  if(image===undefined) {
    //use default 
    metaImageWidth.property = `og:image:width`
    metaImageWidth.content = site.siteMetadata.imgWidth
    metaImageHeight.property = `og:image:height`
    metaImageHeight.content = site.siteMetadata.imgHeight
  }else {
    if(imageWidth && imageHeight) {
      metaImageWidth.property = `og:image:width`
      metaImageWidth.content = imageWidth
      metaImageHeight.property = `og:image:height`
      metaImageHeight.content = imageHeight
    }
  }
  if (title === site.siteMetadata.title || title === "") {
    metaTitleTemplate = site.siteMetadata.title
    title = site.siteMetadata.title
  }
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={metaTitleTemplate}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        metaSiteUrl,
        {
          property: `og:image`,
          content: metaImage,
        },
        metaImageHeight,
        metaImageWidth,
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: metaImage,
        },
        {
          name: `twitter:image:alt`,
          content: title
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.string, 
  imageWidth: PropTypes.number, 
  imageHeight: PropTypes.number, 
  canonicalpath: PropTypes.string, 
}

export default SEO
