import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types"
import "./style.css"

import menu from "../../images/menu.png"
import NavBar from "./navbar"

const NavBarMenu = ({type, displayType, items, activePath, fullPath})=> {
	const [showMenu, setShowMenu] = useState(false);

	useEffect(() => {
		if (typeof window === 'undefined') return;
		function handleResizeEffect() {
			setShowMenu(false);
		}
		window.addEventListener('resize', handleResizeEffect)
		return _ => {
			window.removeEventListener('resize', handleResizeEffect)
		}
	})
	const handleClick = () => {
		setShowMenu(!showMenu);
	};
	const navclass=displayType<2 ?"nav":"menu"

	return <>
			<nav className={"navbar-"+navclass+" "+type + "-"+navclass} >
				{showMenu && <div role="button" tabIndex='0'  className="nav-shadow" onClick={handleClick} onKeyDown={handleClick}>&nbsp;</div>}
				{ navclass === "nav" ? 
					<NavBar items={items} type={type} activePath={activePath} fullPath={fullPath} /> 
				:
					<>
						<NavBar items={items} closeHandler={handleClick}  type={type} activePath={activePath} fullPath={fullPath} hasMenu={true} fadeOption={showMenu?"enter":"leave"} />
						<button className={"navbar-menu "+type + "-menu"} onClick={handleClick}>
							<img src={menu} alt="Menu" />
						</button>
					</>
				} 
			</nav>
	</>
}


NavBarMenu.propTypes = {
  displayType: PropTypes.number,
  activePath: PropTypes.string,
  fullPath: PropTypes.string,
  type: PropTypes.string,
  items: PropTypes.object
};

NavBarMenu.defaultProps = {
  displayType: 0,
  activePath: ``,
  fullPath: ``,
  items: [],
  type: `header`
};

export default NavBarMenu
